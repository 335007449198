import React, { Component } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { GetUserLogin, GetUserAddress } from "../../../../services";
import "../css/index.css";
import swal from "sweetalert";

export default class Address extends Component {
  constructor() {
    super();
    this.state = {
      user: "",
      address: {},
    };
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      address: { ...this.state.address, [name]: value },
    });
    return true;
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let data = { ...this.state.address };
    data.custId = this.state.user.id;
    console.log(data);
    try {
      const res = await GetUserAddress.Create(JSON.stringify(data));
      console.log("Form submission successful!");
      window.location.reload();
      NotificationManager.success("Address Added Successfully", "SUCCCESS");
    } catch (error) {
      console.log(error);
      NotificationManager.success("Address not Added. ", "INPUT ERROR");
    }
  };
  handleclick(e) {
    swal({
      title: "Reached Maximum Limit",
      text: "Please delete an unwanted address to add a new address",
      icon: "error",
      buttons: false,
      // dangerMode: false,
      success: true,
    });
    setTimeout(() => {
      swal.close();
    }, 2 * 1000);
  }
  // handledelete = async (row) => {
  //   console.log(row);

  //   try {
  //     const res = await GetUserAddress.Delete(row.id);
  //     window.location.reload();
  //     NotificationManager.success("Address Delete Successfully", "SUCCCESS");
  //   } catch (error) {
  //     console.log(error);
  //     NotificationManager.success("Address not Delete. ", "INPUT ERROR");
  //   }
  // }
  handledelete = async (row) => {
    console.log(row);

    try {
      const willDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this address!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (willDelete) {
        const res = await GetUserAddress.Delete(row.id);
        // window.location.reload();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        swal("Address Deleted Successfully", {
          icon: "success",
          buttons: false,
          success: true,
        });
      }
    } catch (error) {
      console.log(error);
      swal("Address not Deleted.", "error");
    }
  };

  handleLogout = async (event) => {
    event.preventDefault();
    await GetUserLogin.logout();
    return true;
  };

  async componentDidMount() {
    let email = sessionStorage.getItem("email");
    if (email) {
      let value = await GetUserLogin.getCustomerDetail(email);
      console.log(value);
      if (value) {
        this.setState({ user: value.data });
      }
    }
  }

  render() {
    let { user, address } = this.state;
    const maxAddressLimit = 5;
    const showAddButton =
      user && user.useraddresses && user.useraddresses.length < maxAddressLimit;

    return (
      <div className="wrapper">
        <div className="gambo-Breadcrumb">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <a href="/">
                      <li className="breadcrumb-item">Home</li>
                    </a>{" "}
                    /
                    <li className="breadcrumb-item active" aria-current="page">
                      My Address
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-group">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="user-dt">
                  <div className="user-img">
                    <img src="/img/avatar/img-5.jpg" alt />
                    <div className="img-add">
                      <input type="file" id="file" />
                      {/* <label htmlFor="file"><i className="uil uil-camera-plus" /></label> */}
                    </div>
                  </div>
                  <h4>{user.firstName}</h4>
                  <p>+91 {user.phone}</p>
                  {/* <div className="earn-points"><img src="images/Dollar.svg" alt />Points : <span>20</span></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="left-side-tabs">
                  <div className="dashboard-left-links">
                    <a href="/account/view" className="user-item">
                      <i className="uil uil-apps" />
                      Overview
                    </a>
                    <a href="/account/profile" className="user-item">
                      <i className="mdi mdi-account-outline" />
                      My Account
                    </a>
                    <a href="/account/order/list" className="user-item">
                      <i className="uil uil-box" />
                      My Orders
                    </a>
                    {/* <a href="/account/rewards" className="user-item"><i className="uil uil-gift" />My Rewards</a> */}
                    {/* <a href="/account/wishlist" className="user-item"><i className="uil uil-heart" />Shopping Wishlist</a> */}
                    <a href="/account/address" className="user-item active">
                      <i className="uil uil-location-point" />
                      My Address
                    </a>
                    <a className="user-item" onClick={this.handleLogout}>
                      <i className="uil uil-exit" />
                      Logout
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="dashboard-right">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="main-title-tab">
                        <h4>
                          <i className="uil uil-location-point" />
                          My Address
                        </h4>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="pdpt-bg">
                        <div className="pdpt-title">
                          <h4>My Address</h4>
                        </div>
                        <div className="address-body">
                          {showAddButton ? (
                            <a
                              href=""
                              className="add-address hover-btn"
                              data-toggle="modal"
                              data-target="#address_model"
                            >
                              Add New Address
                            </a>
                          ) : (
                            <div>
                              <a
                                className="add-address hover-btn"
                                onClick={this.handleclick}
                              >
                                Add New Address
                              </a>
                            </div>
                          )}
                          {user ? (
                            user.useraddresses.map((row, index) => (
                              <div className="address-item" key={index}>
                                <div className="address-icon1">
                                  <i className="uil uil-home-alt" />
                                </div>
                                <div className="address-dt-all">
                                  <p>
                                    {row.fullname +
                                      ", " +
                                      row.phone +
                                      ", " }
                                      <br/>
                                      {row.area +
                                      ", " +
                                      row.city +
                                      ", " +
                                      row.discrict +
                                      ", " +
                                      row.states +
                                      ", " +
                                      row.pincode +
                                      ", " +
                                      row.landmark}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "right",
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="address-icon1"
                                    onClick={() => this.handledelete(row)}
                                  >
                                    <i
                                      className="uil uil-trash-alt"
                                      style={{ color: "white" }}
                                    />
                                  </button>
                                </div>
                              </div>
                            ))
                          ) : (
                            <p>Loading...</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="address_model" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Address</h4>
                <button type="button" className="close" data-dismiss="modal">
                  ×
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="control-label">
                          Full Name
                          <span className="required">*</span>
                        </label>
                        <input
                          className="form-control border-form-control"
                          type="text"
                          name="fullname"
                          value={address.fullname}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="control-label">
                          Phone <span className="required">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control border-form-control"
                          name="phone"
                          value={address.phone}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="control-label">
                          city <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control border-form-control"
                          name="city"
                          value={address.city}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="control-label">
                          District <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control border-form-control"
                          name="discrict"
                          value={address.discrict}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="control-label">
                          States <span className="required">*</span>
                        </label>
                        <input
                          className="form-control border-form-control"
                          type="text"
                          name="states"
                          value={address.states}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="control-label">
                          Pincode <span className="required">*</span>
                        </label>
                        <input
                          className="form-control border-form-control"
                          type="text"
                          name="pincode"
                          value={address.pincode}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="control-label">
                          Building No. and Street{" "}
                          <span className="required">*</span>
                        </label>
                        <textarea
                          className="form-control border-form-control"
                          name="area"
                          value={address.area}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="control-label">
                          Landmark <span className="required">*</span>
                        </label>
                        <input
                          className="form-control border-form-control"
                          type="text"
                          name="landmark"
                          value={address.landmark}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <h5 className="text-danger">
                        *Please Provide Your Full Current area with the house
                        number and street.
                      </h5>
                      <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          className="btn col-sm btn-secondary mb-2 btn-lg"
                          onClick={this.handleSubmit}
                        >
                          ADD
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
