import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GetUserLogin } from "../../components/services";
import { NotificationManager } from "react-notifications";

import { auth } from "../../../config/firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

export default function Login() {
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);

  function onCaptchVerify() {
    // auth.settings.appVerificationDisabledForTesting = true;
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            onSignup();
          },
        }
      );
    }
  }
  const onSignup = useCallback(() => {
    console.log(ph);
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+" + ph;
    var testVerificationCode = "123456";
    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        console.log("confirmationResult", confirmationResult);
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        NotificationManager.success("OTP sended successfully!", "Login");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [ph]);

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        setUser(res.user);
        setLoading(false);
        let data = { phone: formik.values.phone };
        let user = await GetUserLogin.getUserLoginV2(data);
        if (user) {
          NotificationManager.success("successfully login", "Login");
          await GetUserLogin.authenticate(user.token, formik.values.phone);
        } else {
          NotificationManager.error("Please recheck your phone");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  const formik = useFormik({
    initialValues: {
      phone: "",
      // otp: "",
    },
    validationSchema: Yup.object().shape({
      phone: Yup.string().length(10).required("Please enter phone"),
      // otp: Yup.string().required("Please enter password"),
    }),
    onSubmit: (values) => {
      setPh(() => "91" + values.phone);
    },
  });

  useEffect(() => {
    if (ph) {
      onSignup();
    }
  }, [ph]);

  return (
    <div>
      <div id="recaptcha-container"></div>
      <div className="modal fade login-modal-main" id="bd-example-modal">
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="login-modal">
                <div className="row">
                  <div className="col-lg-6 pad-right-0">
                    <div className="login-modal-left">
                      <img src="../img/favicon1.png" />
                    </div>
                  </div>
                  <div className="col-lg-6 pad-left-0">
                    <button
                      type="button"
                      className="close close-top-right"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">
                        <i className="mdi mdi-close" />
                      </span>
                      <span className="sr-only">Close</span>
                    </button>
                    {showOTP ? (
                      // <form onSubmit={formik.handleSubmit} noValidate>
                      <div className="login-modal-right">
                        <div className="tab-content">
                          <div
                            className="tab-pane active"
                            id="login"
                            role="tabpanel"
                          >
                            <h5 className="heading-design-h5">
                              Enter your OTP
                            </h5>

                            <fieldset className="form-group">
                              <label>Enter otp</label>
                              <div className="password-container input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="otp"
                                  onChange={(e) => setOtp(e.target.value)}
                                />
                              </div>
                            </fieldset>
                            <fieldset className="form-group">
                              <button
                                type="submit"
                                onClick={onOTPVerify}
                                className="btn btn-lg btn-secondary btn-block"
                              >
                                Login into your account
                              </button>
                            </fieldset>
                          </div>
                        </div>
                        <div className="clearfix" />
                      </div>
                    ) : (
                      <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="login-modal-right">
                          <div className="tab-content">
                            <div
                              className="tab-pane active"
                              id="login"
                              role="tabpanel"
                            >
                              <h5 className="heading-design-h5">
                                Login to your account
                              </h5>
                              <fieldset className="form-group">
                                <label>Enter Mobile</label>
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <input
                                    style={{ width: "20%", userSelect: "none" }}
                                    type="text"
                                    className="form-control"
                                    defaultValue="+91"
                                    disabled
                                  />
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="phone"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.phone}
                                    required
                                  />
                                </span>
                                {formik.touched.phone && formik.errors.phone ? (
                                  <span className="errorMessage">
                                    Please enter your phone number
                                  </span>
                                ) : null}
                              </fieldset>

                              <fieldset className="form-group">
                                <button
                                  disabled={loading}
                                  type="submit"
                                  className="btn btn-lg btn-secondary btn-block"
                                >
                                  Send code via SMS
                                </button>
                              </fieldset>
                            </div>
                          </div>
                          <div className="clearfix" />
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
