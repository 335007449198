import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart } from "../../../../../store/actions/cartActions";
import { GetProductDetails } from "../../../../../components/services";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

class Topstample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productlist: [],
      isloaded: false,
    };
  }

  async componentDidMount() {
    try {
      let category = await GetProductDetails.getAllProductOffer();
      if (category) {
        console.log(category.data);
        this.setState({ productlist: category.data, isloaded: true });
      }
    } catch (e) {
      console.log(e);
    }
  }

  addToCart = (product) => {
    this.props.addToCart(product);
    NotificationManager.success("Added In Cart");
  };

  render() {
    const { productlist, isloaded } = this.state;

    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };

    // Filter products with discount greater than 0 and status is active
    const filteredProductList = productlist.filter(
      (product) => product.discountPer > 0 && product.status === "active"
    );

    return (
      <div>
        {/* New Item slider */}
        <section className="product-items-slider section-padding">
          <div className="container">
            <div className="section-header">{/* <span>For You</span> */}</div>
            <div>
              <span style={{fontSize:"15px", color:"orange"}}>For You</span>
              <h5 className="heading-design-h5">Best Offer Products</h5>
            </div>
            <Slider {...settings}>
              {!isloaded ? (
                <div>
                  <h5>Loading...</h5>
                </div>
              ) : filteredProductList.length > 0 ? (
                filteredProductList.map((row, index) => (
                  <div className="item" key={index}>
                    <div className="product">
                      <Link
                        to={{
                          pathname: `/p/${row.slug}/${row.id}`,
                          state: row,
                        }}
                      >
                        <div className="product-header">
                          <span className="badge badge-success">
                            {row.discountPer}% OFF
                          </span>
                          <img
                            className="img-fluid"
                            src={row.photo}
                            alt="product"
                          />
                          <span className="veg text-success mdi mdi-circle" />
                        </div>
                        <div className="product-body">
                          <h5>{row.name}</h5>
                          <h6>
                            <strong>
                              <span className="mdi mdi-approval" /> Available in
                            </strong>{" "}
                            - {row.unitSize}
                          </h6>
                        </div>
                      </Link>
                      <div className="product-footer">
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm float-right"
                          onClick={() => this.addToCart(row)}
                        >
                          <i className="mdi mdi-cart-outline" /> Add To Cart
                        </button>
                        <p className="offer-price mb-0">
                          {row.netPrice}
                          <i className="mdi mdi-tag-outline" />
                          <br />
                          <span className="regular-price">{row.total}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>No products available</div>
              )}
            </Slider>
          </div>
        </section>
        <NotificationContainer />
      </div>
    );
  }
}

export default connect(null, { addToCart })(Topstample);
