import api from "../../../ApiConfig";
import { Apis } from "../../../../config";
import { NotificationManager } from "react-notifications";

const Create = async (data) => {
  try {
    let result = await api.post(Apis.GetUserAddressCreate, data);
    if (result.data.error) {
      NotificationManager.error(result.data.error);
      return null;
    }
    return result.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const getList = async () => {
  try {
    let result = await api.get(Apis.GetUserAddressList);
    if (result.data.error) {
      NotificationManager.error(result.data.error);
      return null;
    }
    return result.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const Delete = async (id) => {
  try {
    let result = await api.delete(Apis.GetUserAddressDelete + id);
    if (result.data.error) {
      NotificationManager.error(result.data.error);
      return null;
    }
    return result.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default {
  Create,
  getList,
  Delete,
};
