import React, { Component } from "react";
import { connect } from "react-redux";
import {
  removeFromCart,
  incrementToCart,
  decreaseToCart,
  removeFromCartAll,
} from "../../../../store/actions/cartActions";

class Cartsidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grandTotal: "",
      toggle: false,
    };
  }

  handleHide() {
    this.setState({ toggle: !this.state.toggle });
  }
  handleRemoveAll = () => {
    this.props.removeFromCartAll();
    this.setState({ toggle: false });
  };

  render() {
    const { cartItems } = this.props;
    const totalSave = cartItems.reduce(
      (sum, item) => (sum += item.qty * item.netPrice),
      0
    );
    // const totalNetprice = cartItems.reduce(
    //   (sum, row) =>row.qty * row.netPrice,
    //   0
    // );
    // const totalPrice = cartItems.reduce(
    //   (sum, row) =>row.qty * row.price,
    //   0
    // );

    return (
      <div>
        <span data-toggle="offcanvas" className="btn btn-link border-none">
          <i className="mdi mdi-cart" style={{ fontSize: "25px" }} />
          <small className="cart-value">{cartItems.length}</small>
        </span>
        <div className="cart-sidebar">
          {/* <div className="bs-canvas-header side-cart-header p-3 " > */}
          <div className="cart-sidebar-hearder-1">
            <div className="d-inline-block  main-cart-title">
              My Cart <span>({cartItems.length} Items)</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span>
                {cartItems.length > 0 && (
                  <a
                    style={{
                      fontSize: "10px",
                      color: "red",
                      background: "transparent",
                      padding: "3px",
                    }}
                    onClick={this.handleRemoveAll}
                  >
                    <i className="mdi mdi-cart-off" />
                    Empty Cart
                  </a>
                )}
              </span>
            </div>
            <button
              type="button"
              className="bs-canvas-close close"
              data-toggle="offcanvas"
            >
              <i className="mdi mdi-close"></i>
            </button>
          </div>
          <div className="cart-sidebar-body">
            {cartItems.length > 0 ? (
              cartItems.map((row, index) => (
                <div>
                  <div className="cart-item" key={index}>
                    <div className="cart-product-img">
                      <img className="img-fluid" src={row.photo} alt="cart" />
                      <div className="offer-badge">{row.discountPer}% OFF</div>
                    </div>
                    <div className="cart-text">
                      <h4 style={{display: "flex", justifyContent: "space-between"}}>{row.name}
                      {/* <h4>{row.name} <button>{row.discountPer}% OFF</button></h4> */}
                      <button
                            type="button"
                            className="cart-close-btn"
                            onClick={() => this.props.removeFromCart(row)}
                          >
                            {/* <i className="mdi mdi-close" /> */}
                            <i style={{fontSize:"13px"}} className="mdi mdi-delete" />
                          </button>
                          </h4>
                      <div className="cart-radio">
                        <ul className="kggrm-now">
                          <li>
                            <input type="radio" id="a1" name="cart1" />
                            <label>{row.unitSize}</label>
                          </li>
                        </ul>
                      </div>
                      <div className="qty-group">
                        <div className="quantity buttons_added">
                          <input
                            type="button"
                            defaultValue="-"
                            className="minus minus-btn"
                            onClick={() => this.props.decreaseToCart(row)}
                          />
                          <input
                            type="number"
                            value={row.qty}
                            className="input-text qty text"
                            disabled
                          />
                          <input
                            type="button"
                            defaultValue="+"
                            className="plus plus-btn"
                            onClick={() => this.props.incrementToCart(row)}
                          />
                          
                        </div>
                        <div className="cart-item-price">
                          {(() => {
                            const totalNetPrice = (
                              row.netPrice * row.qty
                            ).toFixed(2);
                            const totalNetPriceWithCurrency =
                              "₹" + totalNetPrice;
                            return totalNetPriceWithCurrency;
                          })()}
                          {/* &#x20B9;{row.netPrice*row.qty.toFixed(2)} */}
                          <span className="hide-on-small-screen">
                            {(() => {
                              const totalPrice = (row.price * row.qty).toFixed(
                                2
                              );
                              const totalPriceWithCurrency = "₹" + totalPrice;
                              return totalPriceWithCurrency;
                            })()}
                            {/* &#x20B9;{row.price*row.qty.toFixed(2)} */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center mt-3">
                <img
                  src="/img/empty-cart .png"
                  alt="pickpack"
                  style={{ height: "auto", width: "auto" }}
                />
                <h4>
                  <a className="next-btn16 hover-btn" href="/">
                    Continue Shopping
                  </a>
                </h4>
              </div>
            )}
          </div>
          {cartItems.length > 0 && (
            <div className="cart-sidebar-footer">
              <div className="cart-store-details">
                {/* <p>
                  Sub Total{" "}
                  <strong className="float-right">
                    &#x20B9;
                    {cartItems.reduce(
                      (sum, i) => (sum += i.qty * i.netPrice),
                      0
                    )}
                  </strong>
                </p> */}
                {/* <p>
                  Delivery Charges{" "}
                  <strong className="float-right text-danger">
                    + &#x20B9;30
                  </strong>
                </p> */}

                {/* <h6>Your total savings <strong className="float-right text-danger">&#x20B9;55 (42.31%)</strong></h6> */}
                {/* <h6>Your total savings <strong className="float-right text-danger">&#x20B9;{row.discountPer}</strong></h6> */}
              </div>
              <a href="/checkout">
                <button
                  className=" btn-secondary btn-lg btn-block text-left"
                  type="button"
                >
                  <span className="float-left">
                    <i className="mdi mdi-cart-outline" /><span style={{fontSize:"15px"}}>Proceed to Checkout</span> 
                  </span>
                  <span className="float-right">
                    <strong>
                      &#x20B9;
                      {/* {cartItems.reduce(
                        (sum, i) => (sum += i.qty * i.netPrice),
                        0
                      )} */}
                      {totalSave.toFixed(2)}
                    </strong>{" "}
                    <span className="mdi mdi-chevron-right" />
                  </span>
                </button>
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    cartItems: state.cart.cartItems,
  }),
  { incrementToCart, decreaseToCart, removeFromCart, removeFromCartAll }
)(Cartsidebar);