// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
//   apiKey: "AIzaSyAhYilPHD-hNob-Qh8Vbz5ayy4UyipY8ug",
//   authDomain: "otp-app-demo-4509a.firebaseapp.com",
//   projectId: "otp-app-demo-4509a",
//   storageBucket: "otp-app-demo-4509a.appspot.com",
//   messagingSenderId: "850677737440",
//   appId: "1:850677737440:web:c430fef8546239de72ad85"
// };
const firebaseConfig = {
  apiKey: "AIzaSyCaVfLTFFh1Az72cf5GBAcabMDoc041aMQ",
  authDomain: "pickpack-d4430.firebaseapp.com",
  projectId: "pickpack-d4430",
  storageBucket: "pickpack-d4430.appspot.com",
  messagingSenderId: "145094633888",
  appId: "1:145094633888:web:b005839842f30ec585dcc5",
  measurementId: "G-5WB6C53KKY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);