import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div>
        
        <br />
        <br />
        <br />
        <br />
        <section className="section-padding bg-white border-top">
          <div className="row">
            <div
              className="container"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                className="col-lg-4 col-sm-6"
                style={{
                  border: "3px solid green",
                  background: "#e9f3ed",
                  borderRadius: "15px",
                  boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                  },
                }}
              >
                <br />
                <div className="feature-box1">
                  <i
                    className="mdi mdi-truck-fast"
                    style={{ color: "white", fontSize: "40px" }}
                  />
                  <br />
                  <h4> Fast Delivery</h4>
                  <p>Exclusive Discounts...</p>
                </div>
              </div>
              &nbsp;&nbsp;
              <div
                className="col-lg-4 col-sm-6"
                style={{
                  border: "3px solid blue",
                  background: "#f0f4f8",
                  borderRadius: "15px",
                  boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                  },
                }}
              >
                <br />
                <div className="feature-box2">
                  <i
                    className="mdi mdi-basket"
                    style={{ color: "white", fontSize: "40px" }}
                  />
                  <br />
                  <h4>100% Satisfaction Guarantee</h4>
                  <p>Assurance of Satisfaction...</p>
                </div>
              </div>
              &nbsp;&nbsp;
              <div
                className="col-lg-4 col-sm-6"
                style={{
                  border: "3px solid red",
                  background: "#f7edec",
                  borderRadius: "15px",
                  boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                  },
                }}
              >
                <br />
                <div className="feature-box3">
                  <i
                    className="mdi mdi-tag-heart"
                    style={{ color: "white", fontSize: "40px" }}
                  />
                  <br />
                  <h4>Great Daily Deals Discount</h4>
                  <p>Daily deals are time-sensitive...</p>
                </div>
              </div>
            </div>
            ;
          </div>
        </section>
        <section className="section-padding footer bg-white border-top">
          <div className="container">
            <div className="row">
              <div align="center" className="col-lg-3 col-md-2">
                {/* <h6 className="mb-5 mt-0"> */}
                <a className="header-logo-image" href="/">
                  <img
                    src="/img/favicon2.png"
                    alt="Pickpack"
                    style={{ height: "85px", width: "230px" }}
                  />
                </a>
                <br />
                {/* </h6> */}
                <i>---------------------------------------</i>
                <h6 className="mb-4">
                  <b>FOLLOW US</b>
                </h6>
                <div className="footer-social">
                  <a
                    className="btn-facebook"
                    href="https://www.facebook.com/profile.php?id=100091035347349&mibextid=ZbWKwL"
                    target="blank"
                  >
                    <i className="mdi mdi-facebook-box" />
                  </a>
                  {/* <a className="btn-twitter" href="https://twitter.com/i/flow/login" target='blank'><i className="mdi mdi-twitter" /></a> */}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    className="btn-instagram"
                    href="https://instagram.com/pick.pack2023?igshid=MzNlNGNkZWQ4Mg=="
                    target="blank"
                  >
                    <i className="mdi mdi-instagram" />
                  </a>
                  {/* <a className="btn-whatsapp" href="https://www.whatsapp.com/" target='blank'><i className="mdi mdi-whatsapp" /></a> */}
                  {/* <a className="btn-messenger" href="https://www.messenger.com/" target='blank'><i className="mdi mdi-facebook-messenger" /></a> */}
                  {/* <a className="btn-google" href="https://www.google.com/" target='blank'><i className="mdi mdi-google" /></a> */}
                </div>
              </div>

              <div className="col-lg-3 col-md-2">
                <h6 className="mb-4">
                  <b>ADDRESSES</b>
                </h6>
                <ul>
                  <li>
                    <a
                      className="text-dark"
                      href="https://goo.gl/maps/syWhmNDCjmG1gUXr5"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      BARBARIA
                      <br />
                      DUTTAPULIA BAJAR
                      <br />
                      WEST BENGAL -741501
                    </a>
                  </li>
                  <i>--------------------------------</i>
                  <li>
                    <a
                      className="text-dark"
                      href="https://www.google.com/maps/search/?api=1&query=311%2F302%2C+b.b.d+colony%2C+upen+banarjee+road%2C+parnasree%2C+Kolkata+-700060"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      311/302, B.B.D COLONY
                      <br />
                      UPEN BANERJEE ROAD
                      <br />
                      PARNASREE
                      <br />
                      KOLKATA -700060
                    </a>
                  </li>
                  {/* <ul></ul> */}
                </ul>
              </div>
              <div className="col-lg-3 col-md-3">
                <h6 className="mb-4">
                  <b>CONTACT US</b>
                </h6>
                <p className="mb-0">
                  Call us:
                  <br />
                  <a className="text-dark" href="tel:8335802372">
                    <i className="mdi mdi-phone" /> +91 8335802372
                  </a>
                </p>
                <i>------------------------------</i>
                <p className="mb-0">
                  Mail us:
                  <br />
                  <a className="text-dark" href="mailto:2023pickpack@gmail.com">
                    <i className="mdi mdi-email" /> 2023pickpack@gmail.com
                  </a>
                </p>
              </div>
              <div className="col-lg-3 col-md-2">
                <div>
                  <br />
                  <br />
                  <br />
                  <a
                    // href="https://www.hungrytop.com/privacy-policy"
                    href="privacypolicy.html"
                    target="blank"
                    style={{ color: "blue" }}
                  >
                    <br />
                    Privacy Policy
                  </a>
                  <br />
                  <i>---------------------------------</i>
                  <br />
                  <a
                    // href="https://www.hungrytop.com/terms-and-conditions"
                    href="termsandcondition.html"
                    target="blank"
                    style={{ color: "blue" }}
                  >
                    Terms & Conditions
                  </a>
                  <br />
                  <i>---------------------------------</i>
                  <br />
                  <a
                    // href="https://www.hungrytop.com/refund-and-cancellation-policy"
                    href="refundandcancellation.html"
                    target="blank"
                    style={{ color: "blue" }}
                  >
                    Refund & Cancellation Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="pt-2 pb-2 footer-bottom"
          style={{ background: "#d7d6d6" }}
        >
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-6 col-sm-6">
                <p className="mt-1 mb-0">
                  <strong className="text-dark">
                    &copy; {new Date().getFullYear()} Pickpack.in
                  </strong>
                  . All Rights Reserved
                </p>
              </div>
              <div className="col-lg-6 col-sm-6 text-right">
                {" "}
                {/* Added "text-right" class */}
                <small className="mt-0 mb-0">
                  Made by-
                  <a
                    href="https://www.hungrytop.com/"
                    target="blank"
                    style={{ color: "blue" }}
                  >
                    Hungrytop Technologies Limited
                  </a>
                </small>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
