import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { GetCategoryDetails } from "../../../../components/services";
export default class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
  }

  async componentDidMount() {
    try {
      let category = await GetCategoryDetails.getAllCategoryList();
      if (category) {
        this.setState({ list: category.data });
      }
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 2,
      initialSlide: 2,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            initialSlide: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };
    return (
      // <div style={{ background: "#fff" }}>
      <div>
        <div className="container" id="header-category">
          <div className="container">
            <Slider {...settings}>
              {this.state.list.map((row, i) => {
                // console.log(row);
                return (
                  <div key={i} className="item">
                    <div className="category-item">
                      <Link
                        to={{
                          pathname: `/shop/${row.slug}`,
                        }}
                      >
                        <img
                          className="img-fluid"
                          src={row.image}
                          alt={row.image}
                        />
                        <h6>{row.name}</h6>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}
