import React, { Component } from "react";
import Bannerslider from "../banner-carousel";
import Topsavers from "./top-section";
import Bestofferbanner from "./best-offers-banner";
import Topstample from "./top-stample";
import Category from "../category";

export default class Home extends Component {
  render() {
    const isMobile = window.innerWidth <= 600; // Check if the device width is less than or equal to 600px
    const wrapperClass = isMobile ? "wrapper-mobile" : "";
    return (
      <div className={`wrapper ${wrapperClass}`}>
      {isMobile && <><br /><br /><br /></>}
        <Bannerslider />
        <Category />
        <Topsavers />
        {/* <br /><br /><br /><br /><br /> */}
        <Bestofferbanner />
        <br /><br /><br /><br /><br />
        <Topstample />
      </div>
    );
  }
}
