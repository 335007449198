import React, { Component } from "react";

export default class Pincode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasPincode: false,
      pincode: "",
      formErrors: {
        pincode: "",
      },
    };
  }
  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };
    if (isNaN(value) === false && value.length === 6) {
      formErrors.pincode = "";
      this.setState({ [name]: value });
    } else {
      formErrors.pincode = "Invalid Pincode";
      this.setState({ [name]: "" });
    }
    this.setState({ formErrors });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let { pincode, formErrors } = this.state;
    if (!formErrors.pincode.length) {
      sessionStorage.setItem("pincode", pincode);
      console.log(pincode);
      this.setState({ hasPincode: true });
    }
  };
  handlelogin = async (event) => {
    event.preventDefault();
    sessionStorage.setItem("pincode", "111111");
    this.setState({ hasPincode: true });
  };
  render() {
    let { pincode, formErrors, hasPincode } = this.state;
    // if (hasPincode) {
    //   return <></>;
    // }
    return (
      <div>
        <div
          //   className="modal fade show"
          aria-modal="true"
          style={{
            // display: "block",
            paddingRight: "10px",
            background: "#333333b0",
          }}
          className="modal fade"
          id="checkdelivery"
        >
          <div
            className="modal-dialog modal-sm modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="login-modal">
                  <div className="row">
                    <div className="col-lg-12">
                      <form onSubmit={this.handleSubmit} noValidate>
                        <div className="login-modal-right">
                          <div className="tab-content">
                            <div
                              className="tab-pane active"
                              id="login"
                              role="tabpanel"
                            >
                              <fieldset className="form-group">
                                <label>Enter Your Pincode</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="pincode"
                                  //   value={pincode}
                                  onChange={this.handleChange}
                                />
                                {formErrors.pincode.length > 0 && (
                                  <span className="errorMessage">
                                    {formErrors.pincode}
                                  </span>
                                )}
                              </fieldset>

                              <fieldset className="form-group">
                                <button
                                  type="submit"
                                  data-target="#checkdelivery"
                                  data-toggle="modal"
                                  className="btn btn-lg btn-secondary btn-block"
                                  onClick={this.handleSubmit}
                                  disabled={!!!pincode.length}
                                >
                                  Submit
                                </button>
                              </fieldset>
                              {/* <fieldset className="form-group">
                                <p style={{ textAlign: "center" }}>OR</p>
                              </fieldset>
                              <fieldset className="form-group">
                                <button
                                  type="button"
                                  data-target="#checkdelivery"
                                  data-toggle="modal"
                                  className="btn btn-lg btn-secondary btn-block"
                                //   onClick={this.handlelogin}
                                >
                                  Add New Adreess
                                </button>
                              </fieldset> */}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
