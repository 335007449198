import React, { Component } from "react";
import Login from "../../auth/login";
import Pincode from "./pincode";
import { withRouter } from "react-router-dom";
import Cartsidebar from "../web/views/cart-sidebar";
import { GetUserLogin } from "../../components/services";
import { Redirect } from 'react-router-dom';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      userName: "",
      searchtxt: "",
      hasPincode: true,
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value.replace(/[^\w\s]/gi, "") });
  };

  async componentDidMount() {
    const pincode = sessionStorage.getItem("pincode");
    console.log(pincode);
    let cookies = await GetUserLogin.isAuthenticate();
    this.setState({ token: cookies });
    let email = sessionStorage.getItem("email");
    if (email) {
      let user = await GetUserLogin.getCustomerDetail(email);
      if (user) {
        this.setState({ userName: user.data.firstName });
        sessionStorage.setItem("userdata", JSON.stringify(user));
        if (user.data.useraddresses.length) {
          sessionStorage.setItem(
            "useraddresses",
            JSON.stringify(user.data.useraddresses[0])
          );
        } else {
          // this.setState({ hasPincode: !!pincode });
        }
      }
    } else {
      // this.setState({ hasPincode: !!pincode });
    }
  }

  handleLogout = async (event) => {
    event.preventDefault();
    await GetUserLogin.logout();
  };

  handleClickSearch = (event) => {
    event.preventDefault();
    let searchtxt = this.state.searchtxt.toLowerCase();
    this.props.history.push(`/product/catalogsearch/result/${searchtxt}`);
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.handleClickSearch(event);
    }
  };
  // redirectToProfile = () => {
  //   const { userName } = this.state;
  //   if (!userName) {
  //     return <Redirect to="/account/profile" />;
  //   }
  // };

  render() {
    let { token, userName, searchtxt, hasPincode } = this.state;
    // if (!userName && token) {
    //   return <Redirect to="/account/profile" />;
    // }
    return (
      <div>
        <header className="header clearfix">
          <nav
            className="navbar navbar-light navbar-expand-lg  osahan-menu"
            style={{ background: "#fb6b04" }}
          >
            <div className="container-fluid">
              <a className="navbar-brand1" href="/">
                <span>
                  <img
                    src="/img/favicon1.png"
                    alt="logo"
                    height="67px"
                    width="77px"
                  />
                </span>
                <span id="favicon2-container">
                  <img
                    src="/img/favicon2.png"
                    alt="logo"
                    height="145px"
                    width="180px"
                  />
                </span>
              </a>

              <div className="navbar-collapse" id="navbarNavDropdown">
                <div className="navbar-nav mr-auto mt-2 mt-lg-0 margin-auto top-categories-search-main">
                  <form
                    className="top-categories-search"
                    onSubmit={this.handleClickSearch}
                  >
                    <div style={{ borderRadius: "" }} className="input-group">
                      <input
                        className="form-control"
                        placeholder="Search products"
                        aria-label="Search products in Your City"
                        type="text"
                        name="searchtxt"
                        value={searchtxt}
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                      />
                      {/* <span className="input-group-btn"> */}
                      <button
                        // className="btn btn-search"
                        style={{
                          background: "#c62828",
                          color: "#fff",
                          padding: "3px",
                        }}
                        type="submit"
                        onClick={this.handleClickSearch}
                      >
                        <i
                          style={{ fontSize: "20px" }}
                          className="mdi mdi-file-find"
                        />
                        search
                      </button>
                      {/* </span> */}
                    </div>
                  </form>
                </div>
                <div className="my-2 my-lg-0">
                  <ul className="list-inline main-nav-right">
                    <li className="list-inline-item">
                      <a
                        data-target="#bd-example-modal"
                        data-toggle="modal"
                        className="btn btn-link"
                        style={
                          token
                            ? { display: "none" }
                            : { display: "block", fontSize: "15px" }
                        }
                      >
                        Login/Sign Up
                        <i className="mdi mdi-account-circle" />
                      </a>
                      <div
                        className="dropdown"
                        style={
                          token
                            ? { display: "block", fontSize: "15px" }
                            : { display: "none" }
                        }
                      >
                        <a
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={
                            token
                              ? {
                                  display: "block",
                                  fontSize: "18px",
                                  color: "white",
                                }
                              : { display: "none" }
                          }
                        >
                          <b>
                            {userName}
                            <i className=" dropdown-toggle mdi mdi-account-circle"></i>
                          </b>
                          &nbsp;&nbsp;&nbsp;
                        </a>

                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <p
                            style={{ userSelect: "none" }}
                            className="dropdown-item"
                          >
                            <i className="mdi mdi-account-circle" />
                            Hi{" "}
                            <b>
                              <i>{userName}</i>
                            </b>
                          </p>
                          <div className="dropdown-divider"></div>
                          <a className="dropdown-item" href="/account/view">
                            <i className="uil uil-apps" />
                            Dashboard
                          </a>
                          <a className="dropdown-item" href="/account/profile">
                            <i
                              className="mdi mdi-account-outline"
                              aria-hidden="true"
                            ></i>
                            My Account
                          </a>
                          <a className="dropdown-item" href="/account/address">
                            <i
                              className="mdi mdi-account-location"
                              aria-hidden="true"
                            ></i>
                            My Addresses
                          </a>
                          <a
                            className="dropdown-item"
                            href="/account/order/list"
                          >
                            <i
                              className="mdi mdi-format-list-bulleted"
                              aria-hidden="true"
                            ></i>{" "}
                            Orders List
                          </a>
                          <div className="dropdown-divider"></div>
                          <span
                            className="dropdown-item"
                            onClick={this.handleLogout}
                          >
                            <i className="mdi mdi-lock" aria-hidden="true"></i>{" "}
                            Logout
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="list-inline-item cart-btn">
                      <Cartsidebar />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        {/* login popup */}
        <Login />
        {/* <Pincode /> */}
      </div>
    );
  }
}

export default withRouter(Navigation);
