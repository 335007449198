import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { addToCart } from "../../../../../store/actions/cartActions";
import { GetCategoryDetails } from "../../../../../components/services";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

class Topsavers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productlist: [],
      isloaded: false,
    };
  }

  async componentDidMount() {
    try {
      let category = await GetCategoryDetails.getAllCategoryList();
      if (category) {
        this.setState({ productlist: category.data, isloaded: true });
      }
    } catch (e) {
      console.log(e);
    }
  }

  addToCart = (product) => {
    this.props.addToCart(product);
    NotificationManager.success('Added In Cart');
  };

  render() {
    const { productlist, isloaded } = this.state;

    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };

    return (
      <div>
        <section className="product-items-slider section-padding">
          {productlist.map((row, index) => {
            return (
              <div className="container" id="header-category" key={index}>
                <div >
                {/* <div className="section-header"> */}
                  <h5 className="heading-design-h5">
                    <i >{row.name}</i>
                    <Link
                      to={{
                        pathname: `/shop/${row.slug}`,
                        state: row.products,
                      }}
                    >
                      <span className="float-right text-secondary">
                        View All
                      </span>
                    </Link>
                  </h5>
                </div>
                <Slider {...settings}>
                  {!isloaded ? (
                    <div>
                      <CircularProgress color="secondary" />
                    </div>
                  ) : row.products && row.products.length > 0 ? (
                    row.products.map((row1, index) => {
                      if (row1.status === 'active') {
                        return (
                          <div key={index} className="item">
                            <div className="product">
                              <Link
                                to={{
                                  pathname: `/p/${row1.slug}/${row1.id}`,
                                  state: row1,
                                }}
                              >
                                <div className="product-header">
                                  {row1.discountPer > 0 && (
                                    <span className="badge badge-success">
                                      {row1.discountPer}% OFF
                                    </span>
                                  )}
                                  <img
                                    className="img-fluid"
                                    src={row1.photo}
                                    alt={row1.name}
                                  />
                                </div>
                                <div className="product-body">
                                  <h5>{row1.name}</h5>
                                  <h6>
                                    <strong>
                                      <span className="mdi mdi-approval" /> Available in
                                    </strong>{" "}
                                    - {row1.unitSize}
                                  </h6>
                                </div>
                              </Link>
                              <div className="product-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary btn-sm float-right"
                                  onClick={() => this.addToCart(row1)}
                                >
                                  <i className="mdi mdi-cart-outline" /> Add To Cart
                                </button>
                                <p className="offer-price mb-0">
                                  &#x20B9;{row1.netPrice}{" "}
                                  <i className="mdi mdi-tag-outline" />
                                  <br />
                                  <span className="regular-price">
                                    &#x20B9;{row1.price}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <br /><br />
                          </div>
                        );
                      }
                      return null;
                    })
                  ) : (
                    <div>No products available</div>
                  )}
                </Slider>
              </div>
            );
          })}
        </section>
        <NotificationContainer />
      </div>
    );
  }
}

export default connect(null, { addToCart })(Topsavers);
