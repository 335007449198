import api from "../../../../app/ApiConfig";
import { Apis } from "../../../../config";
import { NotificationManager } from "react-notifications";
const getDeliverySlots = async () => {
    try {
        let result = await api.get(Apis.GetDeliverySlots);
        console.log(result)
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
export default {
    getDeliverySlots,
};