import React, { Component } from "react";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import swal from "sweetalert";
import {
  GetUserLogin,
  GetOrderDetails,
  CartHelper,
  GetLocationDetails,
  GetDeliverySlots,
} from "../../../services";
import {
  removeFromCart,
  incrementToCart,
  decreaseToCart,
} from "../../../../store/actions/cartActions";
import Deliverydetails from "./delivery";
import Loader from "../../../../loader";
class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      subTotal: "",
      saving: "",
      discount: "",
      deliveryCharge: 0,
      deliveryslot: [],
      grandTotal: "",
      email: "",
      customer: "",
      paymentmethod: "",
      deliveryAddress: "",
      isDeliveryDetailsExpanded: true,
      selectedAddress: false,
      selectedDeliverySlot: {},
      isConfirmOrderDisabled: false,
    };
  }
  handleRadioChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleDeliveryAddress = async (value) => {
    const availablePincode = await GetLocationDetails.availablePincode(
      value.pincode
    );
    console.log(availablePincode);
    let cart = this.props.cartItems;
    let subTotal = cart.reduce((sum, i) => (sum += i.qty * i.netPrice), 0);
    let discount = cart.reduce((sum, i) => (sum += i.discount), 0);
    // let deliveryCharge = availablePincode.data.length
    //   ? availablePincode.data[0].deliveryfees
    //   : 0;
    let deliveryCharge;
    if (subTotal <= 299) {
      deliveryCharge = availablePincode.data.length
        ? availablePincode.data[0].deliveryfees
        : 0;
      // } else if (subTotal <= 499) {
      //   deliveryCharge = availablePincode.data.length
      //   ? availablePincode.data[0].deliveryfees+20:0;
      // } else if (subTotal <= 699) {
      //   deliveryCharge = availablePincode.data.length
      //   ? availablePincode.data[0].deliveryfees+10:0;
    } else {
      deliveryCharge = 0;
    }
    // // if (subTotal <= 100) {
    // //   deliveryCharge = 30;
    // // } else if (subTotal <= 400) {
    // //   deliveryCharge = 25;
    // // } else if (subTotal > 400) {
    // //   deliveryCharge = 10;
    // // }

    let subTotalFormatted = parseFloat(subTotal).toFixed(2);
    let grandTotal = parseFloat(
      (subTotal + parseFloat(deliveryCharge)).toFixed(2)
    );
    let saving = cart.reduce((sum, i) => (sum += i.qty * i.discount), 0);

    this.setState({
      subTotal: subTotal.toFixed(2),
      saving: saving,
      discount: discount,
      grandTotal: grandTotal,
      deliveryCharge: deliveryCharge,
      deliveryAddress: value,
    });

    if (availablePincode.data.length) {
      this.setState({ selectedAddress: true });
    } else {
      this.setState({ selectedAddress: false });
      // swal(
      //   "Coming Soon",
      //   `Delivery service currently unavailable for PinCode: ${value.pincode}`,
      //   "error"
      // );
      swal({
        title: "Coming Soon",
        text: `Delivery service currently unavailable for PinCode: ${value.pincode}`,
        icon: "error",
        buttons: false,
        // dangerMode: false,
        success: true,
      });
      setTimeout(() => {
        swal.close();
      }, 2 * 1000);
    }
  };

  getDeliveryslot = async (value) => {
    const availableslot = await GetDeliverySlots.getDeliverySlots(value);
    console.log("Availabe Sllot: ", availableslot);
    this.setState({
      deliveryslot: availableslot.data,
    });
  };

  handleDeliverySlotSelect = (value) => {
    this.setState({ selectedDeliverySlot: value });
  };

  async componentDidMount() {
    this.getDeliveryslot();
    let email = sessionStorage.getItem("email");
    if (email) {
      // let user = await GetUserLogin.getCustomerDetail(email);
      let user = JSON.parse(sessionStorage.getItem("userdata"));
      // console.log(user);
      if (user) {
        this.setState({ customer: user.data, email: email });

        let cart = this.props.cartItems;
        let subTotal = cart.reduce((sum, i) => (sum += i.qty * i.netPrice), 0);
        let discount = cart.reduce((sum, i) => (sum += i.discount), 0);
        // let deliveryCharge = user.data.area.length
        //   ? user.data.area[0].deliveryfees
        //   : this.state.deliveryCharge;
        let deliveryCharge = this.state.deliveryCharge;
        // if (subTotal <= 100) {
        //   deliveryCharge = 30;
        // } else if (subTotal <= 400) {
        //   deliveryCharge = 25;
        // } else if (subTotal > 400) {
        //   deliveryCharge = 10;
        // }
        let grandTotal = parseFloat(
          (subTotal + parseFloat(deliveryCharge)).toFixed(2)
        );
        let saving = cart.reduce((sum, i) => (sum += i.qty * i.discount), 0);

        this.setState({
          subTotal: subTotal.toFixed(2),
          saving: saving,
          discount: discount,
          grandTotal: grandTotal,
          deliveryCharge: deliveryCharge,
        });
      }
    }
  }
  handlePlaceOrder = async (event) => {
    event.preventDefault();
    this.setState({ isConfirmOrderDisabled: true });
    const {
      customer,
      grandTotal,
      deliveryAddress,
      paymentmethod,
      subTotal,
      deliveryCharge,
      selectedDeliverySlot,
    } = this.state;
    let orderId = Math.floor(
      Math.random() * Math.floor(Math.random() * Date.now())
    );
    let { cartItems } = this.props;
    let data = {
      customerId: customer.id,
      paymentmethod: paymentmethod,
      orderId: orderId,
      deliveryAddress: deliveryAddress,
      product: cartItems,
      subtotal: subTotal,
      deliveryfees: deliveryCharge,
      grandTotal: grandTotal,
      // deliveryslot: JSON.stringify(selectedDeliverySlot),
      deliveryslot: selectedDeliverySlot,
    };
    if (data) {
      let order = await GetOrderDetails.getOrderCreateByUser(
        JSON.stringify(data)
      );
      if (order) {
        NotificationManager.success("Successfully Ordered", "Order");
        setTimeout(async function () {
          CartHelper.emptyCart();
          this.setState({ isConfirmOrderDisabled: false });
        }, 1000);
      } else {
        NotificationManager.error("Order is declined", "Order");
        setTimeout(async function () {
          window.location.href = "/failed";
          this.setState({ isConfirmOrderDisabled: false });
        }, 1000);
      }
    }
  };

  loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  handlePaymentSystem = async (e) => {
    e.preventDefault();
    const {
      customer,
      grandTotal,
      deliveryAddress,
      deliveryfees,
      subTotal,
      deliveryCharge,
    } = this.state;
    let { cartItems } = this.props;
    let orderId = `${Math.floor(
      Math.random() * Math.floor(Math.random() * Date.now())
    )}`;
    this.setState({ isLoaded: true });
    console.log("deliveryAddress", deliveryAddress);
    if (deliveryAddress) {
      //payment system
      const res = await this.loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      //creating new order
      let data1 = {
        amount: grandTotal,
        order_id: orderId,
        currency: "INR",
        payment_capture: 1,
      };
      const result = await GetOrderDetails.getPaymentValue(data1);
      if (!result.data) {
        alert("Server error. Are you online?");
        return;
      } else {
        const __DEV__ = document.domain === "localhost";
        var options = {
          key: __DEV__ ? "rzp_live_dsKevpwyY8iqhZ" : "rzp_live_dsKevpwyY8iqhZ",
          currency: result.data.currency,
          amount: result.data.amount * 100,
          order_id: result.data.id,
          name: "Pickpack",
          description: "Test Transaction",
          image: "https://pickpack.in/img/favicon1.png",
          // Image: "/favicon1.png",
          handler: async function (response) {
            const list = {
              custId: customer.id,
              orderCreationId: orderId,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
            };
            const result = await GetOrderDetails.getPaymentOrderList(list);
            if (result.data) {
              const EMPTY_CART = { cartItems: [] };
              const carts = cartItems || EMPTY_CART;
              setTimeout(async function () {
                let data = {
                  customerId: customer.id,
                  paymentmethod: result.data.method,
                  orderId: orderId,
                  deliveryAddress: deliveryAddress,
                  product: cartItems,
                  subtotal: subTotal,
                  deliveryfees: deliveryCharge,
                  grandTotal: result.data.amount / 100,
                };

                let order = await GetOrderDetails.getOrderCreateByUser(
                  JSON.stringify(data)
                );
                if (order) {
                  NotificationManager.success("Successfully Ordered", "Order");
                  // this.setState({ isLoaded: false})
                  CartHelper.emptyCart();
                  setTimeout(async function () {
                    window.location.href = "/order/success";
                  }, 1000);
                }
              }, 1000);
            } else {
              window.location.href = "/order/failed";
            }
            // console.log(result)
          },
          prefill: {
            name: "",
            email: "",
            phone_number: "",
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
        let payementObject = new window.Razorpay(options);
        payementObject.open();
      }
    } else {
      NotificationManager.error("Please! check address details", "Input Field");
    }
  };
  render() {
    const { cartItems } = this.props;
    const { isDeliveryDetailsExpanded } = this.state;
    const {
      subTotal,
      saving,
      discount,
      deliveryCharge,
      deliveryslot,
      grandTotal,
      email,
      customer,
      paymentmethod,
      isLoaded,
      selectedAddress,
      selectedDeliverySlot,
      isConfirmOrderDisabled,
    } = this.state;
    const isAddressSelected = selectedAddress;
    const isDeliverySlotSelected = !!selectedDeliverySlot;
    console.log("selected delivery slot: ", selectedDeliverySlot);
    return (
      <div>
        <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white single-product-header-bk">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <a href="/">
                  <strong>
                    <span class="mdi mdi-home"></span> Home
                  </strong>
                </a>{" "}
                <span class="mdi mdi-chevron-right"></span> <a>Checkout</a>
              </div>
            </div>
          </div>
        </section>

        <section className="checkout-page section-padding">
          <div className="container">
            {isLoaded ? <Loader /> : ""}
            <div className="row">
              <div className="col-md-8">
                <div className="checkout-step">
                  <div className="accordion" id="accordionExample">
                    <div className="card checkout-step-one">
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link checkout-login-bk"
                            disabled
                          >
                            <span className="number">1</span> Login
                            <span className="mdi mdi-checkbox-marked-circle-outline"></span>
                          </button>
                          <div className="_2jDL7w">
                            <div>
                              <span className="dNZmcB">
                                {customer.firstName} <br />
                                {email}
                                <br />
                              </span>
                            </div>
                          </div>
                        </h5>
                      </div>
                    </div>
                    <div className="card checkout-step-two">
                      <div className="card-header" id="headingTwo">
                        <h5 className="mb-0">
                          <button
                            // type="button"
                            // data-toggle="collapse"
                            // data-target="#collapseTwo"
                            // aria-expanded={isDeliveryDetailsExpanded}
                            // aria-controls="collapseTwo"
                            // onClick={this.handleToggleDeliveryDetails}

                            className="btn btn-link collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded={isDeliveryDetailsExpanded}
                            // aria-expanded="true"
                            aria-controls="collapseTwo"
                          >
                            <span className="number">2</span>
                            Delivery Address and Slot:
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseTwo"
                        // className="collapse"
                        className={`collapse ${
                          isDeliveryDetailsExpanded ? "show" : ""
                        }`}
                        aria-labelledby="headingTwo"
                        data-parent="#accordionExample"
                      >
                        <div style={{ padding: "10px" }}>
                          <p style={{ color: "red", userSelect: "none" }}>
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>
                                <u>Select Delivery Address:</u>
                              </span>
                              <span>
                                <a href="../account/address">
                                <button
                                  style={{
                                    background: "red",
                                    color: "white",
                                    padding: "5px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  add address
                                </button>
                                </a>
                              </span>
                            </span>
                          </p>
                          {customer.useraddresses?.map((row, i) => {
                            return (
                              <h4>
                                <input
                                  type="radio"
                                  onClick={() =>
                                    this.handleDeliveryAddress({
                                      fullname: row.fullname,
                                      phone: row.phone,
                                      discrict: row.discrict,
                                      city: row.city,
                                      states: row.states,
                                      area: row.area,
                                      shipping: row.landmark,
                                      pincode: row.pincode,
                                    })
                                  }
                                  id={`address${i}`}
                                  name={`address`}
                                  // value="18/1, Purbanchal Main Rd, Prince Park, Kalikapur, Haltu, Kolkata, West Bengal 700099"
                                />
                                <label htmlFor={`address${i}`}>
                                  &nbsp;&nbsp;&nbsp;{row.area} , {row.city} ,{" "}
                                  {row.discrict} , {row.states} , {row.landmark}{" "}
                                  , {row.pincode}
                                </label>
                              </h4>
                            );
                          })}
                        </div>
                        <div className="radio-label-container">
                          {isAddressSelected && (
                            <p style={{ color: "red", userSelect: "none" }}>
                              <u>Select Delivery Slot:</u>
                            </p>
                          )}
                          {isAddressSelected &&
                            deliveryslot?.map((row, i) => {
                              if (!row.is_active) {
                                return null;
                              }
                              return (
                                <h4 key={i}>
                                  <input
                                    type="radio"
                                    id={`deliveryslot${i}`}
                                    name={`deliveryslot`}
                                    onClick={(e) => {
                                      const formattedSlot = `${row.name} (${row.start_time}-${row.end_time})`;
                                      this.setState({
                                        selectedDeliverySlot: formattedSlot,
                                        isDeliverySlotSelected: true,
                                      });
                                    }}
                                  />

                                  <label
                                    htmlFor={`deliveryslot${i}`}
                                    className="radio-label_3"
                                  >
                                    &nbsp;&nbsp;&nbsp;{row.name}
                                    <br /> ({row.start_time} - {row.end_time})
                                  </label>
                                </h4>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    {isDeliverySlotSelected ? (
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              <span className="number">3</span> Payment
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseThree"
                          className="collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div className="checkout-step-body">
                            <div className="payment_method-checkout">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="rpt100">
                                    <ul className="radio--group-inline-container_1">
                                      <li>
                                        <div className="radio-item_1">
                                          <input
                                            id="cashondelivery1"
                                            value="cash"
                                            name="paymentmethod"
                                            type="radio"
                                            onChange={this.handleRadioChange}
                                          />
                                          <label
                                            htmlFor="cashondelivery1"
                                            className="radio-label_2"
                                          >
                                            Cash on Delivery
                                          </label>
                                        </div>
                                      </li>
                                       <li>
                                        <div
                                          className="radio-item_1"
                                          onClick={this.handlePaymentSystem}
                                        >
                                          <label className="radio-label_2">
                                            Pay Online
                                            <input
                                              // value="card"
                                              name="paymentmethod"
                                              type="button"
                                              onClick={this.handleRadioChange}
                                            />
                                          </label>
                                        </div>
                                      </li> 
                                    </ul>
                                  </div>
                                  {paymentmethod === "cash" ? (
                                    <button
                                      className="next-btn16 hover-btn"
                                      onClick={this.handlePlaceOrder}
                                      disabled={isConfirmOrderDisabled}
                                    >
                                      Confirm Order
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <h5 className="card-header">
                    My Cart{" "}
                    <span className="text-secondary float-right">
                      ({cartItems.length} item)
                    </span>
                  </h5>
                  {cartItems.map((row, index) => (
                    <div className="card-body pt-0 pr-0 pl-0 pb-0" key={index}>
                      <div className="cart-list-product">
                        <img className="img-fluid" src={row.photo} alt="cart" />
                        <span className="badge badge-success">
                          {row.discountPer}% OFF
                        </span>
                        <h5>{row.name}</h5>
                        <h6>
                          <strong>
                            <span className="mdi mdi-approval" /> Available in
                          </strong>{" "}
                          - {row.unitSize}{" "}
                        </h6>
                        <p className="offer-price mb-0">
                          &#x20B9;{row.qty + "x" + row.netPrice}{" "}
                          <i className="mdi mdi-tag-outline" />{" "}
                          <span className="regular-price">
                            &#x20B9;{row.qty + "x" + row.price}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}
                  <div className="total-checkout-group">
                    <div className="cart-total-dil">
                      <h4>Sub Total</h4>
                      <span>&#x20B9;{subTotal}</span>
                    </div>
                    <div className="cart-total-dil pt-3">
                      <h4>Delivery Charges</h4>
                      <span>&#x20B9;{deliveryCharge}</span>
                      {/* <span>&#x20B9;30</span> */}
                    </div>
                  </div>

                  <div className="main-total-cart">
                    <h2>Total</h2>
                    <span>&#x20B9;{grandTotal}</span>
                  </div>
                  {/* <div align="center" className="cart-total-dil saving-total "> */}
                  <div
                    align="center"
                    style={{ background: "#faefa8", paddingTop: "8px" }}
                  >
                    {/* <h6>You saved &#x20B9;{saving} on this order</h6> */}
                    <h6>
                      You saved&nbsp;
                      {/* &#x20B9; */}
                      <b style={{ color: "green" }}>
                        {(() => {
                          const totalSaving = (saving * 1).toFixed(2);
                          const totalSavingWithCurrency = "₹" + totalSaving;
                          return totalSavingWithCurrency;
                        })()}
                      </b>
                      &nbsp; on this order
                    </h6>
                    {/* <h6 style="color: red">You Saved</h6> &#x20B9;{saving} <h6 style="color: red">on this order</h6>  */}
                    {/* <span>&#x20B9;{saving}</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    cartItems: state.cart.cartItems,
  }),
  { incrementToCart, decreaseToCart, removeFromCart }
)(Checkout);
