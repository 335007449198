import React, { Component } from "react";
import { GetUserLogin } from "../../../../services";
import { NotificationManager } from "react-notifications";
import swal from "sweetalert";

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  // validate form errors being empty
  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  // Object.values(rest).forEach((val) => {
  //   val === null && (valid = false);
  // });

  return valid;
};
export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otpsend: false,
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      password: null,
      otp: null,
      agreedToTerms: false,
      isOTPValid: false,
      formErrors: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        password: "",
      },
    };
  }
  componentDidMount() {
    this.setState({ ...this.state, otpsend: false });
  }
  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };
    let isOTPValid = this.state.isOTPValid;

    switch (name) {
      case "firstName":
        formErrors.firstName =
          value.length < 3
            ? "minimum 3 characaters required"
            : value.match(/^[a-zA-Z ]*$/)
            ? ""
            : "**No Number or Special Characters are not Allowed";
        break;
      case "lastName":
        formErrors.lastName =
          value.length < 3
            ? "minimum 3 characaters required"
            : value.match(/^[a-zA-Z ]*$/)
            ? ""
            : "**No Number or Special Characters are not Allowed";
        break;
      case "phone":
        formErrors.phone =
          value.length !== 10
            ? "Phone number must have exactly 10 digits"
            : value.length > 10
            ? "maximum 10 number required"
            : "";
        break;
      case "email":
        formErrors.email = emailRegex.test(value)
          ? ""
          : "invalid email address";
        break;
      case "password":
        formErrors.password =
          value.length < 6 ? "minimum 6 characaters required" : "";
        break;
      case "otp":
        formErrors.otp = value.length !== 6 ? "OTP must be 6 digits" : "";
        isOTPValid = value.length === 6; // Update isOTPValid based on OTP length
        break;
      default:
        break;
    }

    this.setState({ formErrors, [name]: value, isOTPValid });
  };

  handleSubmit = async (event) => {
    // this.setState({ ...this.state, otpsend: true });
    event.preventDefault();
    let { firstName, lastName, phone, email, password, gender } = this.state;
    let data = {
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      email: email,
      password: password,
      gender: gender,
    };
    if (formValid(this.state)) {
      let list = await GetUserLogin.getUserRegister(data);
      if (list) {
        this.setState({ ...this.state, otpsend: true });
        NotificationManager.success("Verify OTP");
      }
    } else {
      NotificationManager.error("Please check your Register", "Input Error");
    }
  };

  handleOTP = async (event) => {
    event.preventDefault();
    let { otp, email } = this.state;
    let data = {
      otp: otp,
      email: email,
    };
    let list = await GetUserLogin.verifyOTP(data);
    if (list) {
      NotificationManager.success("Successfully Added New User");
      // window.location.href = "/";
      swal({
        title: "Account Verified",
        text: "Please Login With Correct email Id and Password",
        icon: "success",
        buttons: true,
        successMode: true,
      }).then(async (success) => {
        if (success) {
          window.location.href = "/login";
        }
      });
    } else {
      NotificationManager.error("invalid OTP", "Input Error");
    }
  };
  handleAgreeChange = (event) => {
    this.setState({ agreedToTerms: event.target.checked });
  };
  render() {
    let {
      otpsend,
      otp,
      firstName,
      lastName,
      phone,
      email,
      password,
      formErrors,
      agreedToTerms,
    } = this.state;
    console.log(otp);
    return (
      <div className="card checkout-step-one">
        <div className="card-header" id="headingOne">
          <h5 className="mb-0">
            <button
              className="btn btn-link"
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <span className="number">1</span> Login or SignUp
            </button>
          </h5>
        </div>
        <div
          id="collapseOne"
          className="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="login-modal login-page-bk">
                  <div className="row">
                    <div className="col-lg-6 pad-right-0">
                      <div className="login-modal-left">
                        <img src="../img/favicon1.png" />
                      </div>
                    </div>
                    <div className="col-lg-6 pad-left-0">
                      {otpsend ? (
                        <div>
                          <h5 className="heading-design-h5">Verify OTP!</h5>
                          <fielldset className="from-control">
                            <lebel>OTP</lebel>
                            <input
                              type="text"
                              className="form-control"
                              name="otp"
                              value={otp}
                              onChange={this.handleChange}
                            />
                          </fielldset>

                          <fieldset className="form-group">
                            <button
                              type="submit"
                              className="btn btn-lg btn-secondary btn-block"
                              onClick={this.handleOTP}
                              disabled={!this.state.isOTPValid}
                            >
                              Verify OTP
                            </button>
                          </fieldset>
                        </div>
                      ) : (
                        <div>
                          <h5 className="heading-design-h5">Register Now!</h5>
                          <fieldset className="form-group">
                            <label>First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="firstName"
                              value={firstName}
                              onChange={this.handleChange}
                            />
                            {formErrors.firstName.length > 0 && (
                              <span className="errorMessage">
                                {formErrors.firstName}
                              </span>
                            )}
                          </fieldset>
                          <fieldset className="form-group">
                            <label>Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="lastName"
                              value={lastName}
                              onChange={this.handleChange}
                            />
                            {formErrors.lastName.length > 0 && (
                              <span className="errorMessage">
                                {formErrors.lastName}
                              </span>
                            )}
                          </fieldset>
                          <fieldset className="form-group">
                            <label>Mobile No.</label>
                            <input
                              type="number"
                              className="form-control"
                              name="phone"
                              value={phone}
                              maxLength={10}
                              onChange={this.handleChange}
                            />
                            {formErrors.phone.length > 0 && (
                              <span className="errorMessage">
                                {formErrors.phone}
                              </span>
                            )}
                          </fieldset>
                          <fieldset className="form-group">
                            <label>Enter Email</label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              value={email}
                              onChange={this.handleChange}
                            />
                            {formErrors.email.length > 0 && (
                              <span className="errorMessage">
                                {formErrors.email}
                              </span>
                            )}
                          </fieldset>
                          <fieldset className="form-group">
                            <label>Enter Password</label>
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              value={password}
                              onChange={this.handleChange}
                            />
                            {formErrors.password.length > 0 && (
                              <span className="errorMessage">
                                {formErrors.password}
                              </span>
                            )}
                          </fieldset>
                          <div className="custom-control custom-checkbox">
                            <span>
                              <input
                                type="checkbox"
                                checked={agreedToTerms}
                                onChange={this.handleAgreeChange}
                                required
                              />
                              <label>
                                I Agree with{" "}
                                <a href="termsandcondition.html" target="blank">
                                  Term and Conditions
                                </a>
                              </label>
                            </span>
                          </div>
                          <fieldset className="form-group">
                            <button
                              type="submit"
                              className="btn btn-lg btn-secondary btn-block"
                              onClick={this.handleSubmit}
                              disabled={!agreedToTerms}
                            >
                              Create Your Account
                            </button>
                          </fieldset>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
