import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Category from "../category";
export default class Bannerslider extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      speed: 4000,
      autoplaySpeed: 6000,
      slidesToShow: 1,
      // slidesToScroll: 1,
      cssEase: "linear", // Added to create a smooth scrolling effect
      swipeToSlide: true, // Added to enable swiping on touch devices
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 8000,
            autoplaySpeed: 10,
            slidesToShow: 1,
            // slidesToScroll: 1,
            cssEase: "linear", // Added to create a smooth scrolling effect
            swipeToSlide: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 8000,
            autoplaySpeed: 10,
            slidesToShow: 1,
            // slidesToScroll: 1,
            cssEase: "linear", // Added to create a smooth scrolling effect
            swipeToSlide: true,
          },
        },
      ],
    };

    // var settings = {
    //     dots: false,
    //     infinite: true,
    //     autoplay: true,
    //     speed: 2000,
    //     autoplaySpeed: 2000     ,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     cssEase: "responsive",
    //     swipeToSlide: true,
    // };

    return (
      <div>
        {/* <Category />     */}
        {/* <div className="container"> */}
        <Slider {...settings}>
          <div className="owl-item" id="owl-item">
            <a href="/shop/oil-masala---more">
              <img src="img/banners/pickpack3.png" alt="Pickpack flex2" />
            </a>
          </div>
          <div className="owl-item" id="owl-item">
            <a href="/shop/atta-rice--dal">
              <img src="img/banners/pickpack2.png" alt="Pickpack flex1" />
            </a>
          </div>
          <div className="owl-item" id="owl-item">
            <a href="/shop/dairy-bread--eggs">
              <img src="img/banners/pickpack 1.png" alt="Pickpack flex4" />
            </a>
          </div>
        </Slider>
      </div>
      // </div>
    );
  }
}
