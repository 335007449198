const API_URL = process.env.REACT_APP_API_URL


const Apis = {
  //Authentication api
  GetUserLogin: `${API_URL}/api/customer/login`,
  GetUserLoginV2: `${API_URL}/api/customer/loginv2`,
  ForgotPassword: `${API_URL}/api/customer/forgotpassword`,
  GetUserRegsiter: `${API_URL}/api/customer/register`,
  verifyOTP: `${API_URL}/api/customer/userOtpVerify`,
  // GetCustomerDetails: `${API_URL}/api/customer/getUserByPhone?phone=`,
  GetCustomerDetails: `${API_URL}/api/customer/getUserByEmailId?email=`,

  //product api
  GetProductById: `${API_URL}/api/product/getWebProductById?id=`,
  GetAllGroceryStaple: `${API_URL}/api/product/getAllGroceryStaple`,
  GetAllProductList: `${API_URL}/api/product/list/`,
  getAllProductOffer: `${API_URL}/api/product/getAllProductOffer`,

  //product api
  GetOrderCreateByUser: `${API_URL}/api/order/create`,
  GetOrderByUser: `${API_URL}/api/order/list`,
  GetOrderStatusUpdate: `${API_URL}/api/order/status/update`,
  GetInvoice: `${API_URL}/api/order/getinvoice`,


  //Filter by category
  GetAllCategoryList: `${API_URL}/api/category/getAllCategory`,
  GetAllCategoryListSlug: `${API_URL}/api/category/cn/list?slug=`,
  GetFilterByCategory: `${API_URL}/api/category/c`,

  //profile 
  GetCustomerUpdateDetails: `${API_URL}/api/customer/update`,

  //Get location 
  GetLocationListDetails: `${API_URL}/api/location/list`,
  GetAreaListDetails: `${API_URL}/api/location/area/list/getbyid?id=`,
  GetAvailablePincode: `${API_URL}/api/location/area/available?pincode=`,

  //Get filter by product
  GetProductByFilter: `${API_URL}/api/product/gcatalogsearch/result?search=`,
  GetCategoryListByFilter: `${API_URL}/api/category/catlogsearch/child-category`,
  GetProductBySubcategory: `${API_URL}/api/category/catlogsearch/product`,

  //Razarpayment
  GetPaymentValue: `${API_URL}/api/payment/orders`, 
  GetPaymentVerification: `${API_URL}/api/payment/verification`, 
  GetPaymentOrderList: `${API_URL}/api/payment/orderlist`, 

  //product api
  GetUserAddressCreate: `${API_URL}/api/useraddress/create`,
  GetUserAddressDelete: `${API_URL}/api/useraddress/delete?id=`,
  GetUserAddressList: `${API_URL}/api/useraddress/list`,


  // Delivery Slots
  GetDeliverySlots:`${API_URL}/api/deliveryslot/list`,


};
export { API_URL, Apis };
